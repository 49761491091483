import LayOut from "../../components/layout";
import "./index.css"

export default function NotFound(){
    return(
        <>
            <LayOut>
            <div className="container" >
                <img className="notFound" src="/assets/images/main/b95e9647229587.5874758db4cef.jpg"/>
            </div>
            </LayOut>
            </>
    )
}