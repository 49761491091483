import LayOut from "../../components/layout";
import SingleGenre from "../../components/singleGenre";

export default function SingleGenrePage(){
    return(
        <>
        <LayOut>
            <SingleGenre/>
        </LayOut>
        </>
    )
}